<template>
	<el-main>
        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item>
                <el-input v-model="form.username" placeholder="请输入账号" type="text"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="form.password" placeholder="请输入密码" type="password"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">登录</el-button>
            </el-form-item>
        </el-form>
	</el-main>
</template>

<script>
import axios from 'axios'
export default {
    name: 'LoginPage',
    data() {
        return {
            form: {
                username: '',
                password: '',
            }
        }
    },
    methods: {
        onSubmit() {
            var _this = this
            axios.post('/api/login', {username: this.form.username, password: this.form.password})
            .then(response => {
                if(response.data.code == 0) {
                    _this.$message({
                        type: 'success',
                        message: '登录成功',
                    })
                    _this.$router.push('/')
                } else {
                    _this.$message({
                        type: 'error',
                        message: response.data.msg,
                    })
                }
            })
        }
    },
    mounted () {

    }
}

</script>