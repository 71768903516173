<template>
  <el-container>
    <el-header>
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
        <el-menu-item index="1">签到中心</el-menu-item>
        <el-menu-item index="2" @click="noteRequest">打开消息提醒</el-menu-item>
        <!-- <el-menu-item index="3" class="fl-right" >退出</el-menu-item> -->
        <el-submenu index="3" class="fl-right">
          <span slot="title">【{{ qdStatus.nickname }}】</span>
          <el-menu-item index="3-1" @click="changePass">修改密码</el-menu-item>
          <el-menu-item index="3-2" @click="handleLogout">退出</el-menu-item>
        </el-submenu>
      </el-menu>
    </el-header>
    <el-main>
      <el-row justify="space-around">
        <el-col :span="8"><el-progress type="circle" :percentage="cTimeP"></el-progress></el-col>
        <el-col :span="8"><el-progress type="circle" :percentage="dTimeP"></el-progress></el-col>
        <el-col :span="8"><el-progress type="circle" :percentage="dTimesP"></el-progress></el-col>
      </el-row>
      <el-row justify="space-around">
        <el-col :span="8"><span>本次剩余时间{{cTimeLeftM}}/{{cTimeLimitM}}分</span></el-col>
        <el-col :span="8"><span>当天剩余时间{{dTimeLeftM}}/{{dTimeLimitM}}分</span></el-col>
        <el-col :span="8"><span>当天剩余次数{{qdStatus.dTimesLeft}}/{{qdStatus.dTimesLimit}}次</span></el-col>
      </el-row>
      <el-row class="btn">
        <el-button v-for="action in actions" :key="action.id" :type="action.type == 1 ? 'primary' : 'success'" round :disabled="action.disabled" @click="handleBtnClick(action.id, action.name, action.type)">{{ action.name }}<span v-if="action.type == 1">({{ action.times_left }})</span></el-button>
      </el-row>
      <el-row class="btn btn-more">
        <el-button v-if="qdStatus.isLeft" type="success" icon="el-icon-check" @click="handleBack" plain>签回</el-button>
        <el-button v-if="cancellable" type="danger" icon="el-icon-close" @click="handleCancel" plain>{{ cancelCountdown }}s</el-button>
      </el-row>

      <el-tabs v-model="activeName" @tab-click="tabClick">
        <el-tab-pane label="普通事件" name="first">
          <el-table
            v-loading="pageFirst.loading"
            :data="tableData"
            height="500"
            border
            style="width: 100%">
            <el-table-column
              prop="id"
              label="id">
            </el-table-column>
            <el-table-column
              prop="action_name"
              label="事件名称">
            </el-table-column>
            <el-table-column
              prop="limittime_m"
              label="限定时间(分)">
            </el-table-column>
            <el-table-column
              prop="starttime_str"
              label="开始时间">
            </el-table-column>
            <el-table-column
              prop="endtime_str"
              label="结束时间">
            </el-table-column>
            <el-table-column
              prop="usetime_m"
              label="实际用时(分)">
            </el-table-column>
            <el-table-column
              prop="overtime_m"
              label="超时(分)">
            </el-table-column>
          </el-table>
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleChangeFirst"
            :page-size="pageFirst.limit"
            :total="pageFirst.total">
          </el-pagination>
        </el-tab-pane>
        <!-- 打卡 -->
        <el-tab-pane label="打卡事件" name="second">
          <el-table
            v-loading="pageSecond.loading"
            :data="tableData1"
            height="500"
            border
            style="width: 100%">
            <el-table-column
              prop="id"
              label="id">
            </el-table-column>
            <el-table-column
              prop="action_name"
              label="事件名称">
            </el-table-column>
            <el-table-column
              prop="starttime_str"
              label="打卡时间">
            </el-table-column>
          </el-table>
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleChangeSecond"
            :page-size="pageSecond.limit"
            :total="pageSecond.total">
          </el-pagination>
        </el-tab-pane>
        <!-- 统计 -->
        <el-tab-pane label="统计" name="third">
          <el-table
            :data="tableData2"
            height="500"
            border
            style="width: 100%">
            <el-table-column
              prop="overtimesTdByTime"
              label="今日超时次数(按次)">
            </el-table-column>
            <el-table-column
              prop="overtimeTdByTimeM"
              label="今日超时分钟数(按次)">
            </el-table-column>
            <el-table-column
              prop="overtimeTdByDayM"
              label="今日超时分钟数(按天)">
            </el-table-column>
            <el-table-column
              prop="overtimesMonBytime"
              label="本月超时次数(按次)">
            </el-table-column>
            <el-table-column
              prop="overtimeMonBytimeM"
              label="本月超时分钟数(按次)">
            </el-table-column>
            <el-table-column
              prop="overtimesMonByday"
              label="本月超时次数(按天)">
            </el-table-column>
            <el-table-column
              prop="overtimeMonByDayM"
              label="本月超时分钟数(按天)">
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-main>
    <el-footer>签到时间以北京时间为准，当前北京时间：{{ bjTime }}</el-footer>
  </el-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'IndexPage',
  props: {
    msg: String
  },
  data() {
      return {
        activeIndex: '1',
        bjTime : '',
        actions: [
        ],
        cancellable: false,
        cancelCountdown: 5,
        cTimer: 0, // 取消操作的定时器
        qdStatus: {
          cTimeLeft: 0,
          cTimeLimit: 60,
          dTimeLeft: 0,
          dTimeLimit: 60,
          dTimesLeft: 0,
          dTimesLimit: 1,
          startTimeStamp: 0,
          username: '',
          nickname: '',
          isLeft: false, // 是否离开
        },
        timeCountTimer: 0,
        tableData: [],
        tableData1: [],
        tableData2: [],
        activeName: 'first',
        pageFirst: {
          loading: false,
          total: 0,
          currentPage: 0,
          limit: 20,
        },
        pageSecond: {
          loading: false,
          total: 0,
          currentPage: 0,
          limit: 20,
        },
        pageThird: {
          loading: false,
        }
      }
    },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    changePass() {
      this.$router.push('/change-pass')
    },
    tabClick(tab) {
      // console.log(tab.name);
      if(tab.name == 'first') {
        this.getQdRegularLog(0, this.pageFirst.limit)
        return
      }
      if(tab.name == 'second') {
        this.getQdWorkLog(0, this.pageSecond.limit)
        return
      }
      if(tab.name == 'third') {
        this.getStatistics()
      }
    },
    handleChangeFirst(val) {
      var valNum = parseInt(val) - 1
      this.pageFirst.currentPage = valNum
      this.getQdRegularLog(valNum, this.pageFirst.limit)
    },
    handleChangeSecond(val) {
      var valNum = parseInt(val) - 1
      this.pageSecond.currentPage = valNum
      this.getQdWorkLog(valNum, this.pageSecond.limit)
    },
    beijingDate(timeStr) { // 当前北京时间, 如果给定如 '01/04/2023 18:00:00' 格式的时间字符串，则转换该字符串为对应北京时间对象
      var date
      if (timeStr) {
          date = new Date(timeStr);
      } else {
          date = new Date();
      }

      var utc = date.getTime() + (date.getTimezoneOffset() * 60000);
      var new_date = new Date(utc + (3600000*8))
      // console.log(new_date.getMinutes())
      return new_date;
    },
    // 输入时间对象，获取y-m-d h:i:s 格式的时间字符串，默认为当前北京时间
    getBjTime(date) {
      date = date ? date : this.beijingDate()
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      var d = date.getDate()
      var h = date.getHours()
      var i = date.getMinutes()
      var s = date.getSeconds()
      return `${y}-${m}-${d} ${h}:${i}:${s}`
    },
    handleBtnClick(id, name, type) {
      this.cancellable = true
      this.actions.forEach(action => {
        action.disabled = true
      });

      var n = this.cancelCountdown - 1
      var _this = this
      this.cTimer = setInterval(function() {
        if(n >= 0) {
          _this.cancelCountdown = n
          n--
        } else {
          clearInterval(_this.cTimer)
          _this.cancelCountdown = 5
          _this.cancellable = false

          // 上下班事件开始提交
          if(type == 2) {
            axios.post('/api/work', {action_id: id, action_name: name, type: type})
            .then(response => {
              if(response.data.code == 0) {
                _this.$message({
                  type: 'sucess',
                  message: response.data.msg
                })
                // 重载状态
                _this.getStatus()
              } else {
                _this.$message({
                  type: 'error',
                  message: response.data.msg
                })
              }
            })
            return
          }
          // 普通事件开始提交。。。
          axios.post('/api/out', {action_id: id, action_name: name, type: type})
          .then(response => {
            if(response.data.code == 0) {
              // 更新本次剩余时间和限制时间
              var date = new Date()
              var nowTimestamp = Math.floor(date.getTime()/1000) // 当前时间戳（s）
              _this.qdStatus.cTimeLeft = response.data.cTimeLimit - (nowTimestamp - response.data.startTimeStamp)
              _this.qdStatus.cTimeLimit = response.data.cTimeLimit
              _this.qdStatus.startTimeStamp = response.data.startTimeStamp
              // 设置状态为离开
              _this.qdStatus.isLeft = true
              // 每5秒更新一次本次离开剩余时间
              _this.timeCountTimer = setInterval(function() {
                _this.timeCount()
              }, 5000)
            } else {
              _this.$message({
                type: 'error',
                message: response.data.msg
              })
            } 
          })
        }
      }, 1000)
    },
    timeCount() { // 计算本次离开剩余时间
      if(this.qdStatus.isLeft) {
        var date = new Date()
        var nowTimestamp = Math.floor(date.getTime()/1000) // 当前时间戳（s）
        this.qdStatus.cTimeLeft = this.qdStatus.cTimeLimit - (nowTimestamp - this.qdStatus.startTimeStamp)

        // 剩余时间小于3分钟警告通知
        if(this.qdStatus.cTimeLeft < 60*3) {
          this.$notify({
            title: '签回提示',
            message: '您的剩余用时少于3分钟，请及时签回',
            type: 'warning'
          });
          // 浏览器通知
          new Notification('签回提示', {body: '您的剩余用时少于3分钟，请及时签回'})
        }
      }
    },
    handleCancel() {
      clearInterval(this.cTimer)
      this.cancelCountdown = 5
      this.cancellable = false

      // 重载状态
      this.getStatus()
    },
    handleLogout() {
      var _this = this
      axios.post('/api/logout')
      .then(response => {
        if(response.data.code == 0) {
          _this.$router.push('/login-page/')
        }
      })
    },
    handleBack() {
      var _this = this
      axios.post('/api/back')
      .then(response => {
        if(response.data.code == 0) {
          _this.$message({
            type: 'success',
            message: response.data.msg + '，总用时' + Math.ceil(response.data.usetime/60) + '分钟'
          })

          // 关闭定时器
          clearInterval(_this.timeCountTimer)
          // 重载状态
          _this.getStatus()
        } else {
          _this.$message({
            type: 'error',
            message: response.data.msg
          })
        }
      })
    },
    // 申请开启浏览器通知
    noteRequest() {
      var _this = this
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          _this.$notify({
            title: '成功',
            message: '您已成功开启浏览器通知',
            type: 'success'
          });
        } else if (permission === 'default') {
          console.log('用户关闭授权 可以再次请求授权');
        } else {
          // console.log('用户拒绝授权 不能显示通知');
          _this.$notify({
            title: '警告',
            message: '建议允许浏览器通知，避免忘记签回',
            type: 'warning'
          });
        }
      });
    },
    // 加载用户状态
    getStatus() {
      var _this = this
      axios.get('/api/status')
      .then(response => {
        var data = response.data
        if(data.code == 0) {
          // 所有事件
          _this.actions = []
          data.actions.forEach(action => {
            var isDisable = false
            if(action.times_left <= 0 && action.type == 1) {  // 普通事件剩余可离开次数小于等于0,则禁用按钮
              isDisable = true
            }
            _this.actions.push({id: action.id, name: action.name, type: action.type, limittime: action.limittime, disabled: isDisable, times_left: action.times_left})
          })
          // 更新用户状态
          _this.qdStatus = data.sdata
          // 如果离开，则定时计算剩余时间，并禁用常规事件按钮
          if(_this.qdStatus.isLeft) {
            _this.timeCountTimer = setInterval(function() {
              _this.timeCount()
            }, 5000)
            _this.actions.forEach(a => {
              a.disabled = true
            });
          }
        } else {
          _this.$message({
            type: 'error',
            message: data.msg
          })
        }
      })
      .catch(function (error) {
        if(error.response.status == 404 && error.response.data.code == 99) {
          _this.$message({
            type: 'error',
            message: '您还未登录'
          })
          window.location.href =  '/#/login-page/'
        }
      })
    },
    // 获取用户普通事件签到记录
    getQdRegularLog(page, limit) {
      this.pageFirst.loading = true
      var _this = this
      axios.post('/api/qdLog', {action_type: 1, page: page, limit: limit})
      .then(response => {
        if(response.data.code == 0) {
          _this.tableData = response.data.data
          _this.pageFirst.total = response.data.total
        } else {
          _this.$message({
            type: 'error',
            message: response.data.msg
          })
        }
        _this.pageFirst.loading = false
      })
    },
    // 获取用户上下班打卡记录
    getQdWorkLog(page, limit) {
      this.pageSecond.loading = true
      var _this = this
      axios.post('/api/qdLog', {action_type: 2, page: page, limit: limit})
      .then(response => {
        if(response.data.code == 0) {
          _this.tableData1 = response.data.data
          _this.pageSecond.total = response.data.total
        } else {
          _this.$message({
            type: 'error',
            message: response.data.msg
          })
        }
        _this.pageSecond.loading = false
      })
    },
    // 获取用户统计数据
    getStatistics() {
      this.pageThird.loading = true
      var _this = this
      axios.post('/api/userStatistics')
      .then(response => {
        if(response.data.code == 0) {
          _this.tableData2 = [response.data.data]
        } else {
          _this.$message({
            type: 'error',
            message: response.data.msg
          })
        }
        _this.pageThird.loading = false
      })
    },
    // 用户设备
    getSystemInfo() {
        var us = navigator.userAgent.toLowerCase();
        if (us.indexOf('android') > -1) {
            return 'android';
        } else if (us.indexOf('iphone') > -1 || us.indexOf('ipad') > -1) {
            return 'ios';
        }
    }
  },
  mounted () {
    // 用户设备
    var ua = this.getSystemInfo()
    if (ua == 'ios' || ua == 'android') {
      this.$alert('禁止手机访问', '提示', {
        showClose: false,
        center: true,
        type: 'warnin',
      }).then(() => {
        window.location.href = '/404.html'
      })
      return
    }

    // 当前北京时间
    var _this = this
    setInterval(function() {
      _this.bjTime =  _this.getBjTime()
    }, 1000)
    
    // 加载用户状态
    this.getStatus()

    // 浏览器通知申请权限
    this.noteRequest()

    // 用户普通事件签到记录
    this.getQdRegularLog(0, this.pageFirst.limit)
  },
  computed: {
    // 百分比
    cTimeP: function() {
      if(this.qdStatus.cTimeLeft < 0) {
        return 0
      }
      return this.qdStatus.isLeft ? Math.floor((this.qdStatus.cTimeLeft / this.qdStatus.cTimeLimit) * 100) : 0
    },
    dTimeP: function() {
      if(this.qdStatus.dTimeLeft < 0) {
        return 0
      }
      return Math.floor((this.qdStatus.dTimeLeft / this.qdStatus.dTimeLimit) * 100)
    },
    dTimesP: function() {
      if(this.qdStatus.dTimesLeft < 0) {
        return 0
      }
      return Math.floor((this.qdStatus.dTimesLeft / this.qdStatus.dTimesLimit) * 100)
    },
    // 对应分钟数
    cTimeLeftM: function() {
      return Math.floor(this.qdStatus.cTimeLeft/60)
    },
    cTimeLimitM: function() {
      return Math.floor(this.qdStatus.cTimeLimit/60)
    },
    dTimeLeftM: function() {
      return Math.floor(this.qdStatus.dTimeLeft/60)
    },
    dTimeLimitM: function() {
      return Math.floor(this.qdStatus.dTimeLimit/60)
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .el-main {
    min-height: 600px;
  }
  .el-footer {
    background-color: #B3C0D1;
    color: #333;
    text-align: center;
    line-height: 60px;
  }
  .el-row {
    text-align: center;
  }
  .fl-right {
    float: right!important;
  }
  .btn {
    padding-top: 50px;
  }
  .btn-more {
    min-height: 100px;
  }
</style>
