import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueRouter from "vue-router"
import IndexPage from "@/components/IndexPage"
import LoginPage from "@/components/LoginPage"
import ChangePass from "@/components/ChangePass"

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: IndexPage,
    name: "IndexPage",
  },
  {
    path: "/login-page/",
    component: LoginPage,
    name: "LoginPage",
  },
  {
    path: "/change-pass",
    component: ChangePass,
    name: "ChangePass",
  }
]

const router = new VueRouter({
  routes
})

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
