<template>
	<el-main>
        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item>
                <el-input v-model="form.password1" placeholder="请输入密码" type="password"></el-input>
            </el-form-item>
            <el-form-item>
                <el-input v-model="form.password2" placeholder="请重复密码" type="password"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">提交</el-button>
                <el-button type="primary" @click="pushBack">返回</el-button>
            </el-form-item>
        </el-form>
	</el-main>
</template>

<script>
import axios from 'axios'
export default {
    name: 'ChangePass',
    data() {
        return {
            form: {
                password1: '',
                password2: '',
            }
        }
    },
    methods: {
        onSubmit() {
            if (this.form.password1 != this.form.password2) {
                this.$message({
                    type: 'error',
                    message: '两次输入密码不一致',
                })
                return
            }
            if (this.form.password1.length < 3) {
                this.$message({
                    type: 'error',
                    message: '密码不能小于3位',
                })
                return
            }
            var _this = this
            axios.post('/api/changePass', {password1: this.form.password1, password2: this.form.password2})
            .then(response => {
                if(response.data.code == 0) {
                    _this.$message({
                        type: 'success',
                        message: '修改成功',
                    })
                    _this.$router.push('/')
                } else {
                    _this.$message({
                        type: 'error',
                        message: response.data.msg,
                    })
                }
            })
        },
        pushBack() {
            this.$router.push('/')
        }
    },
}

</script>